import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const ProtectedRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => {
		if (cookies.get('auth')) {
			return <Component {...props} />;
		} else {
			return (<Redirect to={{ pathname: "/admin-login", state: { from: props.location } }} />);
		}
	}}
	/>
)

export default ProtectedRoute