import React, { Component } from 'react' // ADMIN LOGIN - TEMPORARY
import { connect } from 'react-redux'

import Cookies from 'universal-cookie'
import {message, Form, Icon, Input, Button, Card } from 'antd';

import { withRouter } from "react-router";

//import { login } from '../../actions/authActions'

class NormalLoginForm extends Component {
	constructor(props) {
		super(props);

		this.cookies = new Cookies()

	}


	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
		  if (!err) {
			console.log('Received values of form: ', values);

		
			//	this.props.login(this.state)
			const data = {}
			data.username = values.username
			data.password = values.password
	
		
			fetch('/api/admin-login', {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify(data)
			})
			.then(res => res.json())
			.then(data =>{
				message.info('Page Published');
				console.log(data);
		
				if (data.auth === true) {
		
					console.log("Authorized");
		
					this.cookies.set('token', data.token, { expires: new Date(Date.now() + 86400000) })
					this.cookies.set('auth',  data.auth, { expires: new Date(Date.now() + 86400000) })
		
					this.props.history.push('/admin')
		
				}else{
		
					console.log("Who are you?");
				}
		
			})
		
		
			// fetch('/api/admin-register', {
			// 	method: 'POST',
			// 	headers: {
			// 		'content-type': 'application/json'
			// 	},
			// 	body: JSON.stringify(data)
			// })
			// .then(res => res.json())
			// .then(data =>{
			// 	message.info('Admin Added');
			// 	console.log(data);
			// })
		

		  }
		});
	  };
	render() {

		const { getFieldDecorator } = this.props.form;
    return (
  
		 <Card title="Admin Login"
		 style={{ marginLeft:'35%',marginTop:'5%',width:400}}>

      <Form onSubmit={this.handleSubmit} className="login-form"
	  
	  >
        <Form.Item
		style={{ paddingLeft:"15%"}}
		>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
			  placeholder="Username"
			  style={{ width:250 }}
            />,
          )}
        </Form.Item>
        <Form.Item
		style={{ paddingLeft:"15%"}}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input 
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
			  placeholder="Password"
			  style={{ width:250 }}
            />,
          )}
        </Form.Item>
        <Form.Item
		style={{ paddingLeft:"40%"}}>

          <Button type="primary" htmlType="submit" className="login-form-button"
		  
		  >
            Log in
          </Button>

        </Form.Item>
      </Form>
	  </Card>
    );
	}
}


const WrappedNormalLoginForm = withRouter(Form.create({ name: 'normal_login' })(NormalLoginForm));

class AdminLogin extends Component {

	render() {

     return(

		<WrappedNormalLoginForm />

	 );

	}

}

// const mapStateToProps = state => ({
// 	adminData: state.auth.adminData
// })
export default AdminLogin