import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'


import DRHeader from '../layout/Header'

import {NavLink,Link,BrowserRouter, Route,Switch,withRouter} from 'react-router-dom'

import ProtectedRoute from "../ProtectedRoute"

import { Layout, Menu, Breadcrumb, Icon,Anchor } from 'antd';
import AddNewPage from './pages/AddPage'
import PageList from './pages/PageList'
import AdminSideNav from './AdminSideNav'
import MenuBuider from './MenuBuilder'

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;


//const { Link } = Anchor


class AdminHome  extends Component {


    
    render() {

      console.log(this.props.location);
      const paths = this.props.location.pathname.split('/')

      console.log(paths);

      var selectedKey=[]
      var openKey=[]

      if(paths[2]==="page-builder"){
        
        openKey.push(paths[2])
        selectedKey.push(paths[2]);
 
      }else if(paths[2]==="page-list"){
   
        openKey.push("pl")
        selectedKey.push("pl-"+paths[3]+"-"+paths[4])

      }
      else if(paths[2]==="menu-builder"){
   
        openKey.push("mb")
        selectedKey.push("mb-"+paths[3]+"-"+paths[4])

      }


        return (
         <Layout>

        <AdminSideNav selectedKey={selectedKey} openedKey={openKey}/>
        <Layout style={{ padding: '', background: '#fff' }}>



              
<Switch>

<ProtectedRoute  exact path="/admin">
  Lets start building
</ProtectedRoute>
<ProtectedRoute  exact path="/admin/page-builder/"  component={AddNewPage} />
<ProtectedRoute  exact path="/admin/page-builder/:category"  component={AddNewPage} />
<ProtectedRoute  exact path="/admin/page-builder/:category/:sub_category/:main_menu/:sub_menu"  component={AddNewPage} />
<ProtectedRoute  exact path="/admin/Page-Builder/:category/:sub_category/:main_menu"  component={AddNewPage} />
<ProtectedRoute  exact path="/admin/page-list/:category/:sub_category"  component={PageList} />
<ProtectedRoute  exact path="/admin/menu-builder/:category/:sub_category"  component={MenuBuider} />
 </Switch>




</Layout>

          </Layout>

        )

    }

}

export default AdminHome;
