import { Component } from 'react';

import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, withRouter} from 'react-router-dom'

import logo from './logo.svg';
import './App.css';
import './ReactTags.css';
import 'antd/dist/antd.css'
import 'suneditor/dist/css/suneditor.min.css';   

import ProtectedRoute from "./components/ProtectedRoute"



import DRHeader from './components/layout/Header'
import DRFooter from './components/layout/Footer'
import PageHome from './components/pagelayout/PageHome'
import Home from './components/home/Home'
import PageNotFound from './components/pagelayout/PageNotFound'


// ADMIN


import AddNewPage from './components/admin/pages/AddPage'
import AdminHome from './components/admin/AdminHome'
import AdminLogin from './components/auth/AdminLogin'

import ReactGA from "react-ga4";

ReactGA.initialize("G-Z0DQCGX6GM");
ReactGA.send("pageview");

class App  extends Component {




  render() {



    
  return (

    <BrowserRouter>
    <div className="App">
      {/* <Navbar /> */}
      <DRHeader/>
     
      <Switch>
      
       {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" render={() => (<Redirect to="/software" />)} />  
        <Route  path="/software" component={PageHome} />

        <Route  path="/hardware" component={PageHome} />

        <Route  path="/design" component={PageHome} />


    

                 {/* USER LOGIN */}
      <Route exact path="/admin-login" component={AdminLogin} />
     
            {/* <Route exact path="/admin/add-new-page" component={AddNewPage} /> */}

        <ProtectedRoute   path="/admin" component={AdminHome} />
      
        <Route path="*" component={PageNotFound} />
   
        

      </Switch>
      <DRFooter/>

    </div>
  </BrowserRouter>
    
  );
}
}

export default App;
