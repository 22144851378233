import { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import React from 'react';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import DRHeader from '../../layout/Header'
import {NavLink,Link,BrowserRouter, Route,Switch,withRouter} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Icon,Anchor } from 'antd';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Modal, Button } from 'antd';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;


const axios = require('axios')


// REORDER
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,

	// change background colour if dragging
	background: isDragging ? "yellow" : "#fff",

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? "lightblue" : "lightgrey",
	padding: grid
});
// /. REORDER
 


class PageBuilder extends Component {


	state = {
		editorState: EditorState.createEmpty(),
	  }
	
	  onEditorStateChange: Function = (editorState) => {
		this.setState({
		  editorState,
		});
	  }

	render() {

		const { editorState } = this.state;
	      return(
			 
			<Layout theme="light">
         <Content style={{ background: '#fff' }}>
			<div>
        <Editor
		 
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </div>
		</Content>

			<Sider>
			</Sider>
			</Layout>
		)
	}
}

export default (PageBuilder)