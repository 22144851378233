import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'





import { Result,Layout, Menu, Breadcrumb, Icon,Anchor,BackTop } from 'antd';





 


class PageNotFound  extends Component {





    render() {


        return (
         
       <div>

<Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."

  />,

       </div>
        
          

        )
    
    }

}

export default PageNotFound;
