import React,{ Component } from 'react';

import { Input,Button } from 'antd';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import SortableTree from 'react-sortable-tree';
import Cookies from 'universal-cookie'
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app

import {publishSoftNav} from "../../actions/pageActions"

 class MenuBuider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [
      ],
      add_menu:"",

    };

    this.cookies = new Cookies()
    this.category=""
    this.subcategory=""
    this.data={}
    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleChangeTitle1 = this.handleChangeTitle1.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleOnClick1 = this.handleOnClick1.bind(this)
    
  }

  handleChangeTitle(content){
    this.setState({
        add_menu: content.target.value
    });

    console.log(this.state.add_menu);
  }

  handleChangeTitle1(content){
    this.setState({
        treeData: content.jsObject
    });

    console.log(content.jsObject);
  }

  handleOnClick(e){

    console.log("Button Clicked");

  
    
    this.setState({
        treeData: [...this.state.treeData, {title:this.state.add_menu}]
    });
  }

  handleOnClick1(e){

    console.log("Button Clicked");

   this.data.Category=this.category;
   this.data.SubCategory=this.subcategory; 
   this.data.Menu=this.state.treeData;

	// fetch('http://localhost:5000/api' + '/delete-menu?type=filtered&category='+this.category+'&subcategory='+this.subcategory, {
	// 	method: 'DELETE',

	// })
	// .then(res => res.json())
	// .then(data =>{
     //   message.info('Page Published');
     this.data.token=this.cookies.get('token');
     console.log(this.state.treeData);
     console.log(this.data);
     publishSoftNav(this.data);

//	})

 
  
  }


  
  componentDidMount() {


    console.log(this.props.match.params.category);
    console.log(this.props.match.params.sub_category);

     this.category=this.props.match.params.category.toLowerCase().replace(/-/g, ' ');;
     this.subcategory=this.props.match.params.sub_category.toLowerCase().replace(/-/g, ' ');


    this.setState({
      targetOffset: window.innerHeight / 2,
    });


    var url='/api/menu-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory;

    console.log(url);

    fetch(url)
    .then(res => res.json())
    .then(data =>{
            console.log("getting ldata");
          
            console.log(data[0].Menu);

          //  this.data=data[0].Menu;
          //  console.log(data[0].Menu);
           
           if (typeof(data[0].Menu) != "undefined"){
          
           this.setState({
            treeData: data[0].Menu
        });
        console.log(this.state.treeData);

        }
  

                  }

    

      )





  }

	componentDidUpdate(prevProps, prevState) {

  
		if (this.subcategory !== this.props.match.params.sub_category.toLowerCase().replace(/-/g, ' ')) {
      
      console.log(this.props.match.params.category);
      console.log(this.props.match.params.sub_category);
  
       this.category=this.props.match.params.category.replace(/-/g, ' ');
       this.subcategory=this.props.match.params.sub_category.replace(/-/g, ' ');
  
  
      this.setState({
        targetOffset: window.innerHeight / 2,
      });
  
  
      var url='/api/menu-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory;
  
      console.log(url);
  
      fetch(url)
      .then(res => res.json())
      .then(data =>{
              console.log("getting ldata");
            
            //  this.data=data;
            //  console.log(this.data);
             
             if (typeof(data[0].Menu) != "undefined"){
            
             this.setState({
              treeData: data[0].Menu
          });
          console.log(this.state.treeData);
  
          }
    
  
                    }
  
      
  
        )


		}

	}


  render() {
    return (
      <div style={{ height: 500 }}>
        <SortableTree
          treeData={this.state.treeData}
          onChange={treeData => {this.setState({ treeData })
           console.log(this.state.treeData);
           
        }}
        maxDepth={2}
        />
        <Input placeholder="Menu Title" onChange={this.handleChangeTitle} />,
        <Button type="primary" onClick={this.handleOnClick} >Add menu</Button>
        <JSONInput
        id          = 'a_unique_id'
        placeholder = { this.state.treeData }
        onChange={this.handleChangeTitle1}
        height      = '550px'
    />
      <Button 
      style={{marginRight:0}}
      type="primary" onClick={this.handleOnClick1} >Save</Button>
      </div>
    );
  }
}

export default  MenuBuider;