import { Component } from 'react';

import React from 'react';

import {Link ,NavLink,BrowserRouter} from 'react-router-dom'
import { List, Avatar, Button, Skeleton,Icon } from 'antd';

import reqwest from 'reqwest';

const count = 10;
var skip=0;
var DataUrl = `/api/lazy-page-list?skip=${skip}&count=${count}`;

class PageList extends Component {
 
  constructor(props) {
    super(props);
  this.state = {
    initLoading: true,
    loading: false,
    data: [],
    list: [],
  };

  this.category=""
  this.subcategory=""
  
  }
  componentDidMount() {

    console.log(this.props.match.params.category);
    console.log(this.props.match.params.sub_category);

    skip=0;
     this.category=this.props.match.params.category.toLowerCase().replace(/-/g, ' ');
     this.subcategory=this.props.match.params.sub_category.toLowerCase().replace(/-/g, ' ');

    //  DataUrl = `http://localhost:5000/api/lazy-page-list?'+'skip=${skip}&count=${count}`;

     DataUrl='/api/lazy-page-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory+'&skip='+skip+'&count='+count;


    this.getData(res => {
      this.setState({
        initLoading: false,
        data: res,
        list: res,
      });
    });
  }

  componentDidUpdate() {

    console.log(this.props.match.params.category);
    console.log(this.props.match.params.sub_category);

     if(this.props.match.params.category.toLowerCase().replace(/-/g, ' ')!==this.category||this.props.match.params.sub_category.toLowerCase().replace(/-/g, ' ')!==this.subcategory)
{
     this.category=this.props.match.params.category.toLowerCase().replace(/-/g, ' ');
     this.subcategory=this.props.match.params.sub_category.toLowerCase().replace(/-/g, ' ');

     skip=0;

    //  DataUrl = `http://localhost:5000/api/lazy-page-list?'+'skip=${skip}&count=${count}`;

     DataUrl='/api/lazy-page-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory+'&skip='+skip+'&count='+count;


    this.getData(res => {
      this.setState({
        initLoading: false,
        data: res,
        list: res,
      });
    });


  }

  }


  getData = callback => {
    reqwest({
      url: DataUrl,
      type: 'json',
      method: 'get',
      contentType: 'application/json',
      success: res => {
        callback(res);
      },
    });
  };

  onLoadMore = () => {

    skip+=count;
  
    DataUrl='/api/lazy-page-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory+'&skip='+skip+'&count='+count;


    console.log("skip: "+skip);

    this.setState({
      loading: true,
      list: this.state.data.concat([...new Array(count)].map(() => ({ loading: true, name: {} }))),
    });
    this.getData(res => {
      const data = this.state.data.concat(res);
      this.setState(
        {
          data,
          list: data,
          loading: false,
        },
        () => {
          // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
          // In real scene, you can using public method of react-virtualized:
          // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  };

  render() {
    const { initLoading, loading, list } = this.state;
    const loadMore =
      !initLoading && !loading ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={this.onLoadMore}>load more</Button>
        </div>
      ) : null;

    return (
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={list}
        renderItem={item => (
          
          <List.Item
            style={{backgroundColor:'#fff'}}
            // actions={[<Link to={"/admin/page-builder/"+item.Category.replace(/\s/g , "-")+"/"+item.SubCategory.replace(/\s/g , "-")+"/"+item.MainMenu.replace(/\s/g , "-")+"/"+item.SubMenu.replace(/\s/g , "-")}>Edit</Link> ,(item.Status==="Published")?<a href={"/"+item.Path} target="_blank" style={{textDecoration:"none"}}>View</a>:<h4>View</h4>]}
            actions={[<Link to={"/admin/page-builder/"+item.Path}>Edit</Link> ,(item.Status==="Published")?<a href={"/"+item.Path} target="_blank" style={{textDecoration:"none"}}>View</a>:<h4>View</h4>]}
         >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={
                 
                  <Avatar style={{ backgroundColor: '#7433FF' }} icon={<Icon type="file-text" />} />

                }
                title={item.Title}
                description={item.Category+"|"+item.SubCategory+"|"+item.MainMenu+"|"+item.SubMenu+"\n"
                +"Date Created: "+item.DateCreated+"|"+"Date Updated: "+item.DateUpdated+"\n"
                +"Status: "+item.Status
              }
              />
  
            </Skeleton>
          </List.Item>
        )}
      />
    );
  }
}

export default PageList