import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'

import { Layout, Anchor } from 'antd';


const {  Sider } = Layout;


const { Link } = Anchor

class PageAnchor  extends Component {


    render() {

       
  if(this.props.setnav){
        return(

<Sider style={{ background: '#fff' , height:'44em',position:"sticky",top:50 }}>
<Anchor targetOffset={this.props.targetOffset}style={{paddingLeft:10,background: '#fff'}}>

{this.props.anchormenu}
</Anchor>
</Sider>


        )
} else{

 return null;

}


    }





}


export default PageAnchor;