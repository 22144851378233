import React, {useState, useRef,Component} from 'react'
import { connect } from 'react-redux'
 import { Link,Redirect,useHistory   } from 'react-router-dom'

import PageBuilder from '../pages/PageBuilder'
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; 
import JoditEditor from "jodit-react";
import TagsInput from 'react-tagsinput'
import { render } from 'react-dom';

import { WithContext as ReactTags } from 'react-tag-input';

import Select from 'react-select';
import Cookies from 'universal-cookie'


import { Tabs,Layout,Input, Button,Card ,message,Typography  } from 'antd';

import AdminSideNav from '../AdminSideNav'

import { publishPageInsert, fetchOrderedPageList, publishPageUpdate,deletePage } from '../../../actions/pageActions'
import 'react-tagsinput/react-tagsinput.css'

const { Text } = Typography;

const { TabPane } = Tabs;

const { Header, Content, Footer, Sider } = Layout;

function callback(key) {
	console.log(key);
  }
  // const editor = useRef(null)
  // const [content, setContent] = useState('')
  
  // const config = {
	//   readonly: false // all options from https://xdsoft.net/jodit/doc/
  // }

  const categoryOptions = [
    { value: 'software', label: 'software' },
    { value: 'hardware', label: 'hardware' },
    { value: 'design', label: 'design' },
  ];

var subcategoryOptions = [];

var mainmenuOptions = [];

var submenuOptions = [];



var operationMode1="Publish"
var operationMode2="Draft"



class AddPage extends Component {


  constructor(props) {
    super(props);
    this.state={
      html_content:"",
      initial_content:'Page Content',
      page_title:"Page Title",
      search_title:"Search Title",
      tags: [],
      selectedCategory:null,
      selectedSubCategory:null,
      selectedMainMenu:null,
      selectedSubMenu:null,
      page_id:null,
      operationType1:"Publish",
      operationType2:"Draft",
      disableDelete:true,
      pageType:"New"

    }
    this.cookies = new Cookies()
    this.data={}
   // this.operationMode="Publish"
    this.menudata=[]

    this.category=""
    this.subcategory=""
    this.mainmenu=""
    this.submenu=""




    this.handleChange = this.handleChange.bind(this)
    this.handleTagsChange = this.handleTagsChange.bind(this)
    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleChangeSearchTitle = this.handleChangeSearchTitle.bind(this)
    this.handleOnPublishClick = this.handleOnPublishClick.bind(this)  
    this.handleOnDeletePage = this.handleOnDeletePage.bind(this);     
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleCategoryChange=this.handleCategoryChange.bind(this);
    this.handleSubCategoryChange=this.handleSubCategoryChange.bind(this);
    this.handleMainMenuChange=this.handleMainMenuChange.bind(this);
    this.handleSubMenuChange=this.handleSubMenuChange.bind(this);

  }
  componentDidMount () {
    console.log(this.props.match.params.category);
    console.log(this.props.match.params.sub_category);
    console.log(this.props.match.params.main_menu);
   console.log(this.props.match.params.sub_menu);
    console.log(this.props.match.params.page_id);



    if(this.props.match.params.sub_category===undefined)
    {

      this.category=this.props.match.params.category;
      this.subcategory='overview';
      this.mainmenu='none';
      this.submenu=undefined


    }else{
    
    
    this.category=this.props.match.params.category;
    this.subcategory=this.props.match.params.sub_category;
    this.mainmenu=this.props.match.params.main_menu;
    this.submenu=this.props.match.params.sub_menu;
    
  }


    if (typeof this.category !== 'undefined'&&typeof this.subcategory !== 'undefined'
    && (typeof this.mainmenu !== 'undefined'||typeof this.submenu !== 'undefined'))
    {

      var url1=""
      this.setState({ selectedCategory:{value:this.category,label:this.category}});
      this.setState({ selectedSubCategory:{value:this.subcategory,label:this.subcategory}});
      this.setState({ selectedMainMenu:{value:this.mainmenu,label:this.mainmenu}});
      if(typeof this.submenu !== 'undefined'){
      this.setState({ selectedSubMenu:{value:this.submenu,label:this.submenu}});




  
      url1= '/api/get-page-for-editor?category='+this.category.replace(/-/g, ' ').toLowerCase()+'&subcategory='+this.subcategory.replace(/-/g, ' ').toLowerCase()
      +'&mainmenu='+this.mainmenu.replace(/-/g, ' ').toLowerCase()+'&submenu='+this.submenu.replace(/-/g, ' ').toLowerCase()  
  
      }else{

        url1= '/api/get-page-for-editor?category='+this.category.replace(/-/g, ' ').toLowerCase()+'&subcategory='+this.subcategory.replace(/-/g, ' ').toLowerCase()
        +'&mainmenu='+this.mainmenu.replace(/-/g, ' ').toLowerCase()+'&submenu='+this.submenu  
    


      }


      console.log(this.state.selectedCategory)
      console.log(this.state.selectedSubCategory)
      console.log(this.state.selectedMainMenu)
      console.log(this.state.selectedSubMenu)



     console.log(url1)


     fetch(url1)
     .then(res => res.json())
     .then(data =>{
             console.log("getting fdata");
           
            this.data=data[0];
            console.log(this.data);
            
            if (typeof(this.data) !== "undefined"){
            console.log(this.data.Content);
            
            this.setState({
              initial_content: this.data.Content
           });
           this.setState({
             page_title: this.data.Title
           });
           this.setState({
            search_title: this.data.SearchTitle
          });

          this.setState({
            page_id: this.data._id
          });

          this.setState({
            pageType: this.data.Status
          });

          this.setState({
            disableDelete: false
          });


          if(this.data.Status==="Published"){
          operationMode1='Update'
          operationMode2='UnPublish'

          this.setState({
            operationType1: operationMode1
         });
         this.setState({
          operationType2: operationMode2
       });

          }else if(this.data.Status==="Draft"){
            operationMode1='Publish'
            operationMode2='Draft'

            this.setState({
              operationType1: operationMode1
           });
           this.setState({
            operationType2: operationMode2
         });
          }

          console.log(this.state.html_content);
          console.log(this.state.page_title);
          console.log(this.state.search_title);
          console.log(this.state.page_id);

         }
   

                   }

     

       )

      }else{


        this.setState({
          initial_content: ""
       });
       this.setState({
         page_title: ""
       });
       this.setState({
        search_title: ""
      });



      }


    var url='/api/menu-list?type=all';

    console.log(url);

    fetch(url)
    .then(res => res.json())
    .then(data =>{
            console.log("getting menu");
          
            this.menudata=data
            console.log(this.menudata);

          //  this.data=data[0].Menu;
          //  console.log(data[0].Menu);
           
        //    if (typeof(data[0].Menu) != "undefined"){
          
        //    this.setState({
        //     treeData: data[0].Menu
        // });
        // console.log(this.state.treeData);

        // }
  

                  }

    

      )


  }


  componentDidUpdate () {




  }

  




    handleChange(content){
      this.setState({
        html_content: content.toString()
      });

      //console.log(this.state.html_content);
    }

    handleChangeTitle(content){
      this.setState({
        page_title: content.target.value
      });
      console.log(this.state.page_title);
    }

    handleChangeSearchTitle(content){
      this.setState({
        search_title: content.target.value
      });
      console.log(this.state.page_title);
    }


   handleOnDeletePage(){

       this.data.token=this.cookies.get('token');
       deletePage(this.state.page_id,this.data);
     
       this.props.history.push('/Admin/Page-Builder')
       window.location.reload();

   }


    handleOnPublishClick( mode){

      console.log("Button Clicked");

       console.log(mode);
    

      this.data.Title=this.state.page_title;
      this.data.SearchTitle=this.state.search_title;
      this.data.Content=this.state.html_content;
      this.data.Tags=this.state.tags.map(item => {

        return item.text;
      });


      console.log(this.state.selectedCategory)
      console.log(this.state.selectedSubCategory)
      console.log(this.state.selectedMainMenu)
      console.log(this.state.selectedSubMenu)

     if(this.state.selectedCategory!==null&&((this.state.selectedSubCategory!==null&&this.state.selectedMainMenu!==null)||(this.state.selectedSubCategory.value==='overview'))){

      this.data.Category=this.state.selectedCategory.value.replace(/-/g, ' ').toLowerCase();
      this.data.SubCategory=this.state.selectedSubCategory.value.replace(/-/g, ' ').toLowerCase();
     
    
      if(this.state.selectedMainMenu===null){

        this.data.MainMenu="none"

      }else {
        this.data.MainMenu=this.state.selectedMainMenu.value.replace(/-/g, ' ').toLowerCase();
      }

      




      if(this.state.selectedSubMenu===null){

        this.data.SubMenu="none"

        if(this.data.MainMenu!=="none"){
        this.data.Path=this.data.Category 
        +"\/"+this.data.SubCategory
        +"\/"+this.data.MainMenu
       
        this.data.Path=this.data.Path.replace(/\s/g , "-").toLowerCase();
        }else{

          this.data.Path=this.data.Category 
         
          this.data.Path=this.data.Path.replace(/\s/g , "-").toLowerCase();


        }


      }else {
        this.data.SubMenu=this.state.selectedSubMenu.value.replace(/-/g, ' ').toLowerCase();
        this.data.Path=this.data.Category 
        +"\/"+this.data.SubCategory
        +"\/"+this.data.MainMenu
        +"\/"+this.data.SubMenu 

         this.data.Path=this.data.Path.replace(/\s/g , "-").toLowerCase();
      }



      this.data.token=this.cookies.get('token');

      if(mode==="Publish"){

      this.data.Status="Published"
      
      this.data.DateCreated=Date.now()
      this.data.DateUpdated=Date.now()

      
      
		console.log('Publish', this.data);
    console.log(`Option selected:`, this.state.selectedCategory);


    //categoryOptions.push({ value: 'pink', label: 'Pinkk' })
    
    if(this.state.page_id!==null){
      
      this.setState({
        pageType: "Published"
      });


      this.data.DateUpdated=Date.now()
     publishPageUpdate(this.data);

    }else{
      
      this.data.DateCreated=Date.now()
      this.data.DateUpdated=Date.now()
      publishPageInsert(this.data);
     // window.location.reload();

     if(this.data.SubMenu!=='none')
     this.props.history.push('/Admin/Page-Builder/'+this.data.Category+"/"+this.data.SubCategory+"/"+this.data.MainMenu+"/"+this.data.SubMenu);
     else
     this.props.history.push('/Admin/Page-Builder/'+this.data.Category+"/"+this.data.SubCategory+"/"+this.data.MainMenu);
    
     window.location.reload();
    }
     operationMode1='Update'
     operationMode2="UnPublish"


    } else if(mode==="Update"){


      this.data.Status="Published"

      this.data.DateUpdated=Date.now()
      this.data._id=this.state.page_id;

      
      
		console.log('Update', this.data);
    console.log(`Option selected:`, this.state.selectedCategory);


    //categoryOptions.push({ value: 'pink', label: 'Pinkk' })
    
     publishPageUpdate(this.data);
     operationMode2="UnPublish"

    }else if(mode==="Draft"){


      this.data.Status="Draft"


      
      
		console.log('Update', this.data);
    console.log(`Option selected:`, this.state.selectedCategory);


    //categoryOptions.push({ value: 'pink', label: 'Pinkk' })
    if(this.state.page_id!==null){
      
      this.setState({
        pageType: "Draft"
      });

      this.data.DateUpdated=Date.now()
     publishPageUpdate(this.data);

    }else{

      this.data.DateCreated=Date.now()
      this.data.DateUpdated=Date.now()
      publishPageInsert(this.data);
      if(this.data.SubMenu!=='none')
      this.props.history.push('/Admin/Page-Builder/'+this.data.Category+"/"+this.data.SubCategory+"/"+this.data.MainMenu+"/"+this.data.SubMenu);
      else
      this.props.history.push('/Admin/Page-Builder/'+this.data.Category+"/"+this.data.SubCategory+"/"+this.data.MainMenu);
     
      window.location.reload();
    }
     

    }else if(mode==="UnPublish"){


      this.data.Status="Draft"

      this.data.DateUpdated=Date.now()

      
      
		console.log('Update', this.data);
    console.log(`Option selected:`, this.state.selectedCategory);


    //categoryOptions.push({ value: 'pink', label: 'Pinkk' })
    
    this.setState({
      pageType: "Draft"
    });

     publishPageUpdate(this.data);
     operationMode1='Publish'
     operationMode2="Draft"
     

    }

    this.setState({
      operationType1: operationMode1
   });
   this.setState({
    operationType2: operationMode2
 });


     }else{

   
      message.error('Category error');
   


     }



    }

    //  handleChange(value) {
    //   console.log(`selected ${value}`);
    // }

    handleTagsChange(tag) {
      
      this.setState(state => ({ tags: [...state.tags, tag] }));
    } 
    handleDelete(i) {
        const { tags } = this.state;
        this.setState({
         tags: tags.filter((tag, index) => index !== i),
        });
    }
 
    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }
 
    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();
 
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
 
        // re-render
        this.setState({ tags: newTags });
    }
 

    handleCategoryChange (selectedOption){

      console.log(selectedOption)
      this.setState({ selectedCategory:selectedOption});
      
      if(selectedOption!==null){ 
      this.getSubCategoryOptions(selectedOption.value)
      }else{
        this.setState({ selectedSubCategory:null});
        this.setState({ selectedMainMenu:null});
        this.setState({ selectedSubMenu:null});

        subcategoryOptions=[];
        mainmenuOptions = [];
        submenuOptions = [];
      }

    };

    handleSubCategoryChange (selectedOption){

      console.log(selectedOption)
      this.setState({ selectedSubCategory:selectedOption});

      if(selectedOption!==null){
      this.getMainMenuOptions(selectedOption.value)
      }else{
        this.setState({ selectedMainMenu:null});
        this.setState({ selectedSubMenu:null});
        mainmenuOptions = [];
        submenuOptions = [];
      }


    };

    handleMainMenuChange (selectedOption){

      console.log(selectedOption)
      this.setState({ selectedMainMenu:selectedOption});


      if(selectedOption!==null){
      this.getSubMenuOptions(selectedOption.value)
      }else{

        this.setState({ selectedSubMenu:null});
        submenuOptions = [];
      }
  
    };

    handleSubMenuChange (selectedOption){

      

      console.log(selectedOption)
       this.setState({ selectedSubMenu:selectedOption});
      
    };


    getSubCategoryOptions(value){

      subcategoryOptions=[];

      this.menudata.map(item=>{

        console.log(item.Category);
    
        if(item.Category===value)
        subcategoryOptions.push( {value:item.SubCategory,label:item.SubCategory});
    
    }) 
    
    console.log(subcategoryOptions)
    
    };
    
    
     getMainMenuOptions(value){
    
      mainmenuOptions=[];

      this.menudata.map(item=>{
    
           
    
        if(item.Category===this.state.selectedCategory.value&&item.SubCategory===value){
           
         item.Menu.map(item=>{
    
          mainmenuOptions.push({value:item.title,label:item.title});
    
    
    
           })
    
           console.log(mainmenuOptions)
        
          }
        
        
        }) 
    
    };
    
    
    
    getSubMenuOptions(value){
    
      submenuOptions=[];
    
      this.menudata.map(item=>{
    
           
        console.log(item)
        if(item.Category===this.state.selectedCategory.value&&item.SubCategory===this.state.selectedSubCategory.value){
           
    
          item.Menu.map(item=>{
    
    
            if(item.title===value){
            item.children.map(item=>{
    
              submenuOptions.push({value:item.title,label:item.title});
    
    
    
             })
    
             console.log(submenuOptions)
            }
            
          })
    
        
          }
        
        
        }) 
    
    
    };
  
	render() {

              console.log(this.state.html_content);
          console.log(this.state.page_title);
          console.log(this.state.search_title);
		
		return (
       <Layout theme="light">
       {/* <Sider width={200} style={{ background: '#ddd', overflowY:'auto', height:480,position:"sticky",top:50 }}></Sider> */}
	   
       {/* <AdminSideNav/> */}
     <Content style={{ background: '#fff' }}>

     <Input  value={this.state.page_title} size="large" placeholder="large size"  onChange={this.handleChangeTitle}/>
     <Input  value={this.state.search_title} size="large" placeholder="large size"  onChange={this.handleChangeSearchTitle}/>


        {/* <Editor
		 
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        /> */}
           <SunEditor 
           
           setContents= {this.state.initial_content}
           
           setOptions={{
				    height: 'auto',
					buttonList: [		[
            "undo",
            "redo",
            "font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            "textStyle",
            "removeFormat",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
            "table",
            "link",
            "image",
            "video",
            "fullScreen",
            "showBlocks",
            "codeView",
            "preview",
            "print",
            "save",
            "template"
          ]],
          font:[
            'Arial', 'Comic Sans MS', 'Courier New', 'Impact',
            'Georgia','tahoma', 'Trebuchet MS', 'Verdana','Open Sans'
          ]
					// Other option
      }}
      onChange={this.handleChange}
     
            />
     



	   </Content>
       <Sider width={200} style={{ background: '#ddd', overflowY:'auto', height:500,position:"sticky",top:0 }}>

       <Text code>{"PageType:"+this.state.pageType}</Text>
       <br/>

       <Button  style={{background: '#ccc'} }  onClick={() => this.handleOnPublishClick(operationMode2)}>{this.state.operationType2}</Button>
    <br/>
    <Button type="primary" onClick={() => this.handleOnPublishClick(operationMode1)}>{this.state.operationType1}</Button>
    <Button disabled={this.state.disableDelete} type="danger" onClick={this.handleOnDeletePage}>Delete Page</Button>



       <Card title="MENU | TAG CARD"  headStyle={{ background: '#ddd'}}>


       <Select

        value={this.state.selectedCategory}
        isClearable={true}
        onChange={this.handleCategoryChange}
        options={categoryOptions}
      />

      <Select

      value={this.state.selectedSubCategory}
      isClearable={true}
      onChange={this.handleSubCategoryChange}
      options={subcategoryOptions}
      />

      <Select

      value={this.state.selectedMainMenu}
      isClearable={true}
      onChange={this.handleMainMenuChange}
      options={mainmenuOptions}
      />

      <Select

      value={this.state.selectedSubMenu}
      isClearable={true}
      onChange={this.handleSubMenuChange}
      options={submenuOptions}
      />


 

    
    <ReactTags      autofocus={false}
                    tags={this.state.tags}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleDrag={this.handleDrag}
                    />
                    
    </Card>

   

       </Sider>
       
	   </Layout>

		)
	}
}


export default AddPage