import { Component } from 'react';
import React from 'react';
import 'antd/dist/antd.css'

import {NavLink} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Icon,Anchor ,Input,Col, Row} from 'antd';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;





class DRHeader extends Component {




  render() {

    console.log("footer:"+window.location.toString().split("/")[3]);


    var path=window.location.toString().split("/")[3]


  if(path!=='admin'){


    return (

      <div className="header">
    
        
    
       
      {/* <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={['1']}

      >
        <Menu.Item key="1">
          <NavLink to='/'>Home</NavLink>
        </Menu.Item>
        <Menu.Item key="2">
        <NavLink to='/Software'>Software</NavLink>
        </Menu.Item>
        <Menu.Item key="3">nav 3</Menu.Item>
      </Menu> */}

<a href="/" class="logo" style={{marginLeft:40}}> <img src="\drona_logo512.png" alt="Drona Aviation" width="50" height="50"></img></a>

<ul style={{paddingLeft:40}}>

  <li ><NavLink to='/software'>Software</NavLink></li>
  <li ><NavLink to='/hardware'>Hardware</NavLink></li>
  <li ><NavLink to='/design'>Design</NavLink></li>

</ul>






    </div>
     
    )
  }else{

    return (

      <div className="header" >
    
 




 

  <Row gutter={16}>
      <Col span={1}>
      <img style={{marginLeft:5}} src="\drona_logo512.png" alt="Drona Aviation" width="50" height="50"></img>  
      </Col>
      <Col span={8}>
      <h1> ADMIN PANEL</h1>
      </Col>

    </Row>


    </div>


    )
  }


  }



}

export default DRHeader;