import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'


import DRHeader from '../layout/Header'
import {Link ,NavLink,BrowserRouter} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Icon,Anchor,Typography,Input, AutoComplete  } from 'antd';


const { Option, OptGroup } = AutoComplete;


const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

const { Title,Text  } = Typography;


class PageSideNav  extends Component {

  constructor(props) {
    super(props);

    this.navmenu=[];
    this.Category="";
    this.SubCategory=""
    this.MainMenu=""
    this.SubMenu=""
    this.currentmenu=[""];
    this.currentmenugroup=[""];
    this.ismenuset=false;
    this._ismounted = true;
    this.navbar=null


   
  }

  componentDidMount() { 
    this._ismounted = true;
    console.log(this.props.navdata);

  }
  
  componentWillUnmount() {
     this._ismounted = false;
  }

  componentDidUpdate(){

    console.log(this.props.navdata);
    if(this.props.mainmenu==="introduction"){

      console.log("###Rferesh coming sidenav");
     // this.forceUpdate();

    }
  }

  handleClick = e => {
    console.log('click ', e);
    this.setState({
      selectedKey: [e.key],
    });
    window.location.reload();

  };



    render() {
      this.navmenu=this.props.navdata;
      this.Category=this.props.category;;
      this.SubCategory=this.props.subcategory;
      this.MainMenu=this.props.mainmenu;;
      this.SubMenu=this.props.submenu;
       var key=0;
       var childkey=0;

       
       console.log(this.navmenu)
      this.navbar=this.navmenu.map(item => {

        if (typeof item.children !== 'undefined' && item.children.length > 0) {
          // the array is defined and has at least one element
          
          const childmenu= item.children.map(childitem => {
           
            childkey++;
            if(this.SubMenu===childitem.title.toLowerCase()){
              console.log("Adding Key: "+childkey);
              this.currentmenu.pop();
            this.currentmenu.push(''+childkey)
            this.ismenuset=true;
            }

             var path='/'+this.Category+'/'+this.SubCategory+'/'+item.title+'/'+childitem.title;
            
             path=path.replace(/\s/g , "-").toLowerCase();
             console.log(path)

            return <Menu.Item key={childkey} >
            <NavLink to ={path}   style={{ fontSize:15 }}> {childitem.title}</NavLink>
            </Menu.Item>
          
          })

          key++;
   
          if(this.ismenuset){
            this.currentmenugroup.pop()
          this.currentmenugroup.push("key"+key)
          this.ismenuset=false;
          }

          return <SubMenu
          key={"key"+key}
          title={
          <Text strong>{item.title}</Text>
          }

          onTitleClick={this.handleTitleClick}
    
        >
        {childmenu}


        </SubMenu>

      }else{
        childkey++;

        if(this.MainMenu===item.title.toLowerCase()){

          console.log("Adding Key #2: "+childkey);
          this.currentmenu.pop();
          this.currentmenu.push(''+childkey)
          
          }
          var path='/'+this.Category+'/'+this.SubCategory+'/'+item.title;
            
          path=path.replace(/\s/g , "-").toLowerCase();

          console.log(path)

        return <Menu.Item key={childkey} >
        <NavLink to ={path}   style={{ fontSize:15 }}> {item.title}</NavLink>
        </Menu.Item>



      }

      })
     

      console.log(this.currentmenu);
      console.log(this.currentmenugroup);


      console.log("##setnav "+this.props.setnav);
      if(this.props.setnav===true){


        return (
<Sider width={200} style={{ background: '#fff', overflowY:'auto', height:'44em',position:"sticky",top:50 }}>
                 

         
                 <Menu
                   mode="inline"
                   style={{ height: '100%',background: '#fff' }}
                   defaultSelectedKeys={this.currentmenu}
                   defaultOpenKeys={this.currentmenugroup}
                 >
                   {/* <SubMenu
                     key="sub1"
                     title={
                      <Text strong>Ant Design</Text>
                     }

                     
                   >
                     <Menu.Item key="1" >
                     <NavLink to ='/Software/User' activeClassName="active"  style={{ fontSize:15 }}> zcdsvfs</NavLink>
                     </Menu.Item>
                     <Menu.Item key="2">
                     <NavLink to ='/'>Home</NavLink>
                     </Menu.Item>
                     <Menu.Item key="3">option3</Menu.Item>
                     <Menu.Item key="4">option4</Menu.Item>
                   </SubMenu>
                   <SubMenu
                     key="sub2"
                     title={
                      <Text strong>Ant Design</Text>
                     }
                   >
                     <Menu.Item key="5"><h4>option5 efewrgreg <br/>rgregergregwegwegegw wg ewgwgewwgwwe</h4></Menu.Item>
                     <Menu.Item key="6">option6</Menu.Item>
                     <Menu.Item key="7">option7</Menu.Item>
                     <Menu.Item key="8">option8</Menu.Item>
                   </SubMenu>
                   <SubMenu
                     key="sub3"
                     title={
                      <Text strong>Ant Design</Text>
                     }
                   >
                     <Menu.Item key="9">option9</Menu.Item>
                     <Menu.Item key="10">option10</Menu.Item>
                     <Menu.Item key="11">option11</Menu.Item>
                     <Menu.Item key="12">option12</Menu.Item>
                   </SubMenu> */}

                   {this.navbar}

                 </Menu>
     
               </Sider>



        )
        }else{

       return null;

        }
      
      
      }
    
    
    
    }


    export default PageSideNav;