import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'


import DRHeader from '../layout/Header'
import {Link ,NavLink,BrowserRouter} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Icon,Anchor,Typography ,Select } from 'antd';
const { Option } = Select;

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

const { Title,Text  } = Typography;


class AdminSideNav  extends Component {

      constructor(props) {
        super(props);
         
        this.state = {
          collapsed: false,
          selectedKey:[],
          openedKey:[]
        };
        
        this.setKey=[]
        this.handleClick=this.handleClick.bind(this)
        this.handleTitleClick=this.handleTitleClick.bind(this)

  
      }
  
      componentDidMount(){
  
        console.log(this.props.selectedKey)
        console.log(this.props.openedKey)
  
        this.setState({
          selectedKey:this.props.selectedKey
        },() => {
          console.log(this.state.selectedKey);
        });


        
        this.setState({
          openedKey: this.props.openedKey
        },() => {
          console.log(this.state.openedKey)
        });



      //  this.defaultSelect=this.props.selectedKeys;
     //   this.defaultOpen=this.props.openedKey;
        
        
  
      }

      componentDidUpdate(prevProps, prevState){

        console.log(this.props.selectedKey)
        console.log(this.props.openedKey)
        if(this.props.selectedKey[0]!==this.state.selectedKey[0]){

        this.setState({
          selectedKey:this.props.selectedKey
        },() => {
          console.log(this.state.selectedKey);
        });

        this.setState({
          openedKey: this.props.openedKey
        },() => {
          console.log(this.state.openedKey)
        });

      }


  
      //   console.log(this.props.selectedKey)
      //   console.log(this.props.openKey)

      //   console.log(this.state.defaultSelect)
      //   console.log(this.state.defaultOpen)

      //   if(this.state.defaultSelect===this.props.selectedKey||this.state.defaultOpen===this.props.openKey){

      //     this.setState({
      //       defaultSelect:this.props.selectedKey
      //     });
  
      //     this.setState({
      //       defaultOpen: this.props.openKey
      //     });

      //   console.log(this.state.defaultSelect)
      //   console.log(this.state.defaultOpen)
      // }
      }

      onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
      };
  

      handleClick = e => {
        console.log('click ', e);
        this.setState({
          selectedKey: [e.key],
        });


         if(e.key==="page-builder")
          window.location.reload();

      };

      handleTitleClick = e => {
        console.log('click ', e);


        var isItemPresent=false;

         console.log('stateOpen', this.state.openedKey);

         if(typeof(this.state.openedKey)!=='undefined'){

          console.log('size',this.state.openedKey.length)

          if(this.state.openedKey.length>0){
         this.state.openedKey.forEach((item)=>{

          console.log('item', item);
               if(item===e.key){
                console.log("pop")
                isItemPresent=true;
                //this.setKey.pop(item)
               }
               else{
                console.log("push")
               // this.setKey.push(e.key)
               }



         })

         if(isItemPresent)
         this.setKey.pop(e.key)
         else
         this.setKey.push(e.key)



         console.log('setKkey',this.setKey);
         this.setState({
           openedKey:this.setKey
         });

        }else{

          this.setKey.push(e.key)
          this.setState({
            openedKey:[e.key]
          });
       
        }


        }
       
     
      };
    

      render() {





          return (
<Sider  style={{height:500, overflowY:'auto',position:"sticky",background: '#fff',top:0 }} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className="logo" />
          <Menu  theme="light"  style={{ height: '100%',background: '#fff' }} selectedKeys={this.state.selectedKey}
           openKeys={this.state.openedKey}
          onClick={this.handleClick}
         mode="inline">
            <Menu.Item key="page-builder">
            <Link to="/admin/page-builder/">Page Builder</Link>
            </Menu.Item>
            <SubMenu
              key="pl"
              title={
                 <span>Page List</span>
              }
              onTitleClick={this.handleTitleClick}
            >
              
              <Menu.ItemGroup key="g1" title="Software">

              <Menu.Item key="pl-software-overview"> <Link to="/admin/page-list/software/overview">Overview</Link></Menu.Item>
              <Menu.Item key="pl-software-drone-programming"> <Link to="/admin/page-list/software/drone-programming">Drone Programming</Link></Menu.Item>
              <Menu.Item key="pl-software-remote-programming"><Link to="/admin/page-list/software/remote-programming">Remote Programming</Link></Menu.Item>
              <Menu.Item key="pl-software-configuration"><Link to="/admin/page-list/software/configuration">Configuration</Link></Menu.Item>
              <Menu.Item key="pl-software-tutorials"><Link to="/admin/page-list/software/tutorials">Tutorials</Link></Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup key="g2" title="Hardware">

              <Menu.Item key="pl-hardware-overview"> <Link to="/admin/page-list/hardware/overview">Overview</Link></Menu.Item>
              <Menu.Item key="pl-hardware-flight-controllers"> <Link to="/admin/page-list/hardware/flight-controllers">Flight Controllers</Link></Menu.Item>
              <Menu.Item key="pl-hardware-accessories"><Link to="/admin/page-list/hardware/accessories">Accessories</Link></Menu.Item>
              <Menu.Item key="pl-hardware-parts"><Link to="/admin/page-list/hardware/parts">Parts</Link></Menu.Item>
              <Menu.Item key="pl-hardware-tutorials"><Link to="/admin/page-list/hardware/tutorials">Tutorials</Link></Menu.Item>
              </Menu.ItemGroup>


              <Menu.ItemGroup key="g3" title="Design">

              <Menu.Item key="pl-design-overview"> <Link to="/admin/page-list/design/overview">Overview</Link></Menu.Item>
              <Menu.Item key="pl-design-forms"> <Link to="/admin/page-list/design/forms">Forms</Link></Menu.Item>
              <Menu.Item key="pl-design-accessories"><Link to="/admin/page-list/design/accessories">Accessories</Link></Menu.Item>
              <Menu.Item key="pl-design-tutorials"><Link to="/admin/page-list/design/tutorials">Tutorials</Link></Menu.Item>
              </Menu.ItemGroup>
             
            </SubMenu>
            <SubMenu
              key="mb"
              title={
                 <span>Menu Builder</span>
              }
              onTitleClick={this.handleTitleClick}
            >
              
              <Menu.ItemGroup key="g4" title="Software">



              <Menu.Item key="mb-software-drone-programming"> <Link to="/admin/menu-builder/software/drone-programming">Drone Programming</Link></Menu.Item>
              <Menu.Item key="mb-software-remote-programming"><Link to="/admin/menu-builder/software/remote-programming">Remote Programming</Link></Menu.Item>
              <Menu.Item key="mb-software-configuration"><Link to="/admin/menu-builder/software/configuration">Configuration</Link></Menu.Item>
              <Menu.Item key="mb-software-tutorials"><Link to="/admin/menu-builder/software/tutorials">Tutorials</Link></Menu.Item>

              </Menu.ItemGroup>
              <Menu.ItemGroup key="g5" title="Hardware">

              <Menu.Item key="mb-hardware-flight-controllers"> <Link to="/admin/menu-builder/hardware/flight-controllers">Flight Controllers</Link></Menu.Item>
              <Menu.Item key="mb-hardware-accessories"><Link to="/admin/menu-builder/hardware/accessories">Accessories</Link></Menu.Item>
              <Menu.Item key="mb-hardware-parts"><Link to="/admin/menu-builder/hardware/parts">Parts</Link></Menu.Item>
              <Menu.Item key="mb-hardware-tutorials"><Link to="/admin/menu-builder/hardware/tutorials">Tutorials</Link></Menu.Item>
              </Menu.ItemGroup>


              <Menu.ItemGroup key="g6" title="Design">

              <Menu.Item key="mb-design-forms"> <Link to="/admin/menu-builder/design/forms">Forms</Link></Menu.Item>
              <Menu.Item key="mb-design-accessories"><Link to="/admin/menu-builder/design/accessories">Accessories</Link></Menu.Item>
              <Menu.Item key="mb-design-tutorials"><Link to="/admin/menu-builder/design/tutorials">Tutorials</Link></Menu.Item>
              </Menu.ItemGroup>
             
            </SubMenu>

          </Menu>
        </Sider>
  
  
          )}
      
      
      
      }
  
  
      export default AdminSideNav;