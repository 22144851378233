import { Component, useRef } from 'react';

import React from 'react';


import 'antd/dist/antd.css'


import {Link as Mink,BrowserRouter} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Icon,Anchor } from 'antd';
import PageSideNav from './PageSideNav'
import PageAnchor from './PageAnchor'

import { fetchPage } from '../../actions/pageActions'
import renderHTML from 'react-render-html';

import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; 
import Item from 'antd/lib/list/Item';

var scrollToElement = require('scroll-to-element');



const { SubMenu } = Menu;
const { Header, Content, Footer, Sider,BackTop } = Layout;


const { Link } = Anchor


class PageContent  extends Component {


  constructor(props) {
    super(props);
 
    this.state={
      targetOffset: undefined,
      html_content:"",
      page_title:"",
      navmenu:[],
      anchormenu:[]
    }

    this.category="Software"
    this.subcategory="Cygnus"
    this.mainmenu=""
    this.submenu=""
    this.data={}
    this.anchor=[]
    this.setsidenav=true
    //this.anchormenu=[]
    

  }


    


      componentDidMount() {

        this.setState({
          targetOffset: window.innerHeight/10,
        });


    


        if(this.props.category===undefined&&this.props.subcategory===undefined){

          console.log(this.props.match.params.category);
          console.log(this.props.match.params.sub_category);
          console.log(this.props.match.params.main_menu);
          console.log(this.props.match.params.sub_menu);

         this.category=this.props.match.params.category.replace(/-/g, ' ');
         this.subcategory=this.props.match.params.sub_category.replace(/-/g, ' ');
         this.mainmenu=this.props.match.params.main_menu.replace(/-/g, ' ');
       //  this.submenu=this.props.match.params.sub_menu.replace(/-/g, ' ');

         if(typeof(this.props.match.params.sub_menu)!=='undefined')
         this.submenu=this.props.match.params.sub_menu.replace(/-/g, ' ');
         else
         this.submenu="none"
      
         if(this.subcategory!=='overview')
         this.setsidenav=true;
         else
         this.setsidenav=false;

        }else{

          console.log("catprop#"+this.props.category);
          console.log("subcateprop#"+this.props.subcategory);


          this.category=this.props.category
          this.subcategory=this.props.subcategory
          this.mainmenu="none"
          this.submenu="none"

          this.setsidenav=false;

        }
         
         this.data.Category=this.category;
         this.data.SubCategory=this.subcategory; 
         this.data.MainMenu=this.mainmenu;
         this.data.SubMenu=this.submenu;

         console.log(this.submenu)

        // fetchPage(this.data);
  
      

         // var url='http://localhost:5000/api/menu-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory;

          fetch('/api/menu-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory)
          .then(res => res.json())
          .then(data =>{
                  console.log("getting ldata");
                  console.log(data);
                
                //  this.navmenu=data[0].Menu;
                //  console.log(this.navmenu);
                 
                 if (typeof(data[0]) !== "undefined"){
                
                 this.setState({
                  navmenu: data[0].Menu
              });
              console.log(this.state.navmenu);
      
              }
        
      
                        }
      
          
      
            )
      

            var url1= '/api/get-page?category='+this.category+'&subcategory='+this.subcategory
            +'&mainmenu='+this.mainmenu+'&submenu='+this.submenu  
     
             fetch(url1)
             .then(res => res.json())
             .then(data =>{
                     console.log("getting fdata");
                   
                     console.log(data);
                    this.data=data[0];
                    console.log(this.data);
                    
                    if (typeof(this.data) !== "undefined"){
                    console.log(this.data.Content);
                    
                    this.setState({
                     html_content: this.data.Content
                   });
                   this.setState({
                     page_title: this.data.Title
                   });
     
                   if (typeof(this.data) !== "undefined"){
                   var root = document.createElement("div");
     
                   root.innerHTML = this.data.Content;
           
           
                  var arr=[];
           
                 [].map.call( root.querySelectorAll("h2"), function(v){
           

                   arr.push({
                     id:  v.id ,
                     content: v.textContent,
                   
                     });

                    
                  
               });
               
           
                  this.anchor=arr;
                  console.log(this.anchor);
                  var anchordata= this.anchor.map(function(item) {


                    if(item.id!==""){
                    return <Link   href={"#"+item.id} title={item.content} />
                    }
                  })
                  

                  this.setState({
                    anchormenu: anchordata
                  });

                  console.log(this.state.anchormenu);
     
     
                  
                 }}else{
     
                   this.setState({
                     html_content: "<h3>PAGE ERROR<h3>"
                   });
                   this.setState({
                     page_title: "No Title"
                   });
     
                   this.setState({
                    anchormenu: []
                  });


                 }
           
     
                           }
     
             
     
               )



               
            if(this.props.location!==undefined) { 

              console.log(this.props.location);
              const paths = this.props.location.pathname.split('/')
        
              console.log(paths);
              
               if(this.props.location.hash!==""){

             
             var hash=this.props.location.hash;
             setTimeout(function () {

               scrollToElement(hash,
                {
                  offset: -62,
                  ease: 'out-quad',
                  duration: 1500
              })

             },1000)
 
            
               }else{
  


              scrollToElement(".header",
                {
                  offset: 0,
                  ease: 'out-quad',
                  duration: 500
              })

               }

              }else{
  


                scrollToElement(".header",
                  {
                    offset: 0,
                    ease: 'out-quad',
                    duration: 500
                })
  
                 }


      }

 
      componentDidUpdate(prevProps, prevState) {





     console.log("component updated");


    if(this.props.category===undefined&&this.props.subcategory===undefined){

      console.log(this.props.match.params.category);
      console.log(this.props.match.params.sub_category);
      console.log(this.props.match.params.main_menu);
      console.log(this.props.match.params.sub_menu);


     var curcategory=this.props.match.params.category.replace(/-/g, ' ')
     var cursubcategory=this.props.match.params.sub_category.replace(/-/g, ' ');
     var curmainmenu=this.props.match.params.main_menu.replace(/-/g, ' ');
     var cursubmenu="none"

     if(typeof(this.props.match.params.sub_menu)!=='undefined')
       cursubmenu=this.props.match.params.sub_menu.replace(/-/g, ' ');

     if(cursubcategory!=='overview')
    this.setsidenav=true;
    else
    this.setsidenav=false;

    }else{


      console.log("catprop#@"+this.props.category);
      console.log("subcateprop#@"+this.props.subcategory);

      var curcategory=this.props.category
      var cursubcategory=this.props.subcategory
      var curmainmenu="none"
      var cursubmenu="none"
    
      this.setsidenav=false;

    }


     if(cursubcategory!==this.subcategory&&curmainmenu==="introduction"){
           
      console.log("###Rferesh coming");
     // this.forceUpdate();
     // window.location.reload(true);   
     return;

    }






       console.log(curcategory);
       console.log(cursubcategory);
       console.log(curmainmenu);
       console.log(cursubmenu);

       console.log(this.category);
       console.log(this.subcategory);
       console.log(this.mainmenu);
       console.log(this.submenu);


        if (this.mainmenu !== curmainmenu||this.submenu!==cursubmenu||
          this.subcategory!==cursubcategory|| this.category!==curcategory) {



          

          console.log("de");

        //   this.category=this.props.match.params.category.replace(/-/g, ' ');
        //   this.subcategory=this.props.match.params.sub_category.replace(/-/g, ' ');
        //   this.mainmenu=this.props.match.params.main_menu.replace(/-/g, ' ');
        // //  this.submenu=this.props.match.params.sub_menu.replace(/-/g, ' ');
 
        //   if(typeof(this.props.match.params.sub_menu)!=='undefined')
        //   this.submenu=this.props.match.params.sub_menu.replace(/-/g, ' ');
        //   else
        //   this.submenu="none"


        this.category=curcategory;
        this.subcategory=cursubcategory;
        this.mainmenu=curmainmenu;
        this.submenu=cursubmenu;





          // if(this.mainmenu==="introduction"){
           
          //   console.log("###Rferesh coming");
          //  // this.forceUpdate();
          //  // window.location.reload(true);   
          //   return;

          // }

        //  if(typeof(this.props.match.params.sub_menu)!=='undefined')
        //  this.submenu=this.props.match.params.sub_menu;
        //  else
        //  this.submenu="None";
       
       
         console.log(this.submenu)
         
        //  this.data.Category=this.category;
        //  this.data.SubCategory=this.subcategory; 
        //  this.data.MainMenu=this.mainmenu;
        //  this.data.SubMenu=this.submenu;

        // fetchPage(this.data);
  


          var url='/api/menu-list?type=filtered&category='+this.category+'&subcategory='+this.subcategory;

          fetch(url)
          .then(res => res.json())
          .then(data =>{
                  console.log("getting ldata");
                  console.log(data);
                
                //  this.navmenu=data[0].Menu;
                //  console.log(this.navmenu);
                 
                if (typeof(data) != "undefined"){
                
                  this.setState({
                   navmenu: data[0].Menu
               });
               console.log(this.state.navmenu);
       
               }
      
                        }
      
          
      
            )


            var url1= '/api/get-page?category='+this.category+'&subcategory='+this.subcategory
            +'&mainmenu='+this.mainmenu+'&submenu='+this.submenu  
     
             fetch(url1)
             .then(res => res.json())
             .then(data =>{
                     console.log("getting fdata");
                   
                     console.log(data);
                    this.data=data[0];
                    console.log(this.data);
                    
                    if (typeof(this.data) !== "undefined"){
                    console.log(this.data.Content);
                    
                    this.setState({
                     html_content: this.data.Content
                   });
                   this.setState({
                     page_title: this.data.Title
                   });
     
                   var root = document.createElement("div");
     
                   root.innerHTML = this.data.Content;
           
           
                  var arr=[];
           
                 [].map.call( root.querySelectorAll("h2"), function(v){
           

                   arr.push({
                     id:  v.id ,
                     content: v.textContent,
                   
                     });
                    
                  
               });
           
               this.anchor=arr;
               console.log(this.anchor);
               var anchordata= this.anchor.map(function(item) {



                if(item.id!==""){
                 return <Link   href={"#"+item.id} title={item.content} />
                }
               })
               

               this.setState({
                 anchormenu: anchordata
               });

               console.log(this.state.anchormenu);
     
     
                 }else{
     
                   this.setState({
                     html_content: "<h3>PAGE ERROR<h3>"
                   });
                   this.setState({
                     page_title: "No Title"
                   });

                   this.setState({
                    anchormenu: []
                  });


     
                 }
                 
             

                  window.scrollTo(0, 0)
  

            //   scrollToElement(".nav-header",
            //   {
            //     offset: 0,
            //     ease: 'out-quad',
            //     duration: 500
            // })
           
     
                           }
     
             
     
               )




                     }


      }



    render() {
    //  const page_content = this.data.Content.toString();
      console.log(this.state.html_content);
    //   var result = this.state.html_content.match(/<h2>(.*?)<\/h2>/g).map(function(val){
    //     return val.replace(/<\/?h2>/g,'');
    //  });
     
    console.log(this.state.anchormenu);
//   console.log(this.props.match.params.item);
   console.log(this.state.navmenu);


        return (
          
            <Layout >

           
           <PageSideNav setnav={this.setsidenav} navdata={this.state.navmenu} submenu={this.submenu} mainmenu={this.mainmenu} category={this.category}
           subcategory={this.subcategory}
           
           />


            <Content style={{  minHeight: 700,margin:0, background: '#fff' }}>
            {/* <article id="pageContentUser" dangerouslySetInnerHTML={{ __html: this.state.html_content ? this.state.html_content : '404' }}>
										</article> */}

                  <div class="sun-editor-editable" >

                    {renderHTML(this.state.html_content)}

                    </div>
                 
                    {/* <SunEditor 
                    
                    appendContents= {this.state.html_content}
                     setOptions={{
                    
                      minHeight: 700,
                      height: 'auto',
                 // Or Array of button list, eg. [['font', 'align'], ['image']]
                    // Other option
                    buttonList:[],
                    showPathLabel : false,
                    charCounter : false,
                }}
                showToolbar={false}


                disable={true}

                    /> */}
           
</Content>

       <PageAnchor  setnav={this.setsidenav} targetOffset={this.state.targetOffset} anchormenu={this.state.anchormenu}  />



</Layout>

        )
    }
    
    
    
    }


    export default PageContent;