import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'


import DRHeader from '../layout/Header'
import {Link as Mink,BrowserRouter} from 'react-router-dom'



import { Layout, Menu, Breadcrumb, Icon,Anchor ,Carousel,Card, Col, Row } from 'antd';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;


const { Link } = Anchor


class Home  extends Component {


    render() {
        return (
         <Layout>


          <Content>

<Carousel autoplay>
    <div>
      <h3>1</h3>
    </div>
    <div>
      <h3>2</h3>
    </div>
    <div>
      <h3>3</h3>
    </div>
    <div>
      <h3>4</h3>
    </div>
  </Carousel>



           <h1>
               Landing Page

           </h1>

           <div style={{ background: '#ECECEC', padding: '30px' }}>
    <Row gutter={16}>
      <Col span={8}>
        <Card title="Card title" bordered={false}>
          Card content
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Card title" bordered={false}>
          Card content
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Card title" bordered={false}>
          Card content
        </Card>
      </Col>
    </Row>
  </div>



          </Content>

          </Layout>

        )

    }

}

export default Home;
