import {
	BASE_URL,
	FETCH_PAGE_LIST,
	FETCH_PAGE_ADMIN,
	POST_PUBLISH_PAGE,
	POST_SAVE_DRAFT_PAGE,
	DELETE_PAGE,
	FETCH_PAGE_USER,
	GET_ORDERED_PAGE_LIST,
	GET_MEDIA_LIST,
	GET_ORDERED_MENU_LIST,
	POST_SAVE_ORDERED_MENU_LIST,
	POST_ADD_SLIDER,
	FETCH_SLIDER_LIST,
	FETCH_SLIDER_MEDIA_LIST,
	SAVE_ORDERED_SLIDER_LIST
} from './types'

import { message, Button } from 'antd';

// POST: PUBLISH PAGE INSERT - NEW PAGE
export const publishPageInsert = (data)  => {


     console.log("Publishing Page");
	 console.log(data);


	fetch(BASE_URL + '/add-page/', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				'x-access-token': data.token
			},
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(data =>{
			message.info('Page Published');
			console.log(data);
        })
}


export const publishPageUpdate = (data)  => {


	console.log("Updating Page");
	console.log(data);


   fetch(BASE_URL + '/update-page/', {
		   method: 'PUT',
		   headers: {
			   'content-type': 'application/json',
			   'x-access-token': data.token

		   },
		   body: JSON.stringify(data)
	   })
	   .then(res => res.json())
	   .then(data =>{
		   message.info('Page Updated');
		   console.log(data);
	   })
}


// FETCH PAGE DATA USER SIDE
export const fetchPage = (data) =>  {
	console.log("Getting Page");
	console.log(data);


   fetch(BASE_URL + '/get-page?category='+data.Category+'&subcategory='+data.SubCategory
    +'&mainmenu='+data.MainMenu+'&submenu='+data.SubMenu  
   , {
		   method: 'GET',
	   })
	   .then(res => res.json())
	   .then(data =>{
		   message.info('Page Retrieved');
		   console.log(data);
	   })
}



export const publishSoftNav = (data)  => {


	console.log("Publishing SoftNav");


	console.log(data);


		fetch(BASE_URL + '/menu-add/', {
			method: 'PUT',
			headers: {
				'content-type': 'application/json',
				'x-access-token': data.token
			},
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(data =>{
			message.info('Page Published');
			console.log(data);
		})
	  

  
}

// FETCH PAGE DATA USER SIDE
export const deletePage = (_id,data) =>  {
	console.log("Deleting Page");
	console.log(_id);


   fetch(BASE_URL + '/delete-page?_id='+_id  
   , {
		   method: 'DELETE',
		   headers: {
			'content-type': 'application/json',
			'x-access-token': data.token
		},
	   })
	   .then(res => res.json())
	   .then(data =>{
		   message.info('Page Retrieved');
		   console.log(data);
	   })
}
