import { Component } from 'react';
import React from 'react';
import 'antd/dist/antd.css'

import {NavLink} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Icon,Anchor ,Input, Card, Col, Row} from 'antd';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;





class DRFooter extends Component {




  render() {




    var path=window.location.toString().split("/")[3]


  if(path!=='admin'){

    return (

        <Footer>
  <div style={{ background: '#ECECEC', padding: '30px' }}>
    <Row gutter={16}>
      <Col span={8}>
      <ul className="list-unstyled text-small">
                <li><a className="text-muted" href="https://create.dronaaviation.com/assets/downloads/PlutoBlocks/Projects%20With%20Pluto_%20Using%20Pluto%20Blocks%20%28Version%201.0%29.pdf"  target="_blank">Projects with PlutoBlocks</a></li>
                <li><a className="text-muted" href="https://create.dronaaviation.com/assets/downloads/cygnus/Projects%20with%20Pluto_%20Using%20Cygnus%20IDE%20%28Version%201.1%29.pdf
"  target="_blank">Projects with Cygnus</a></li>
                <li><a className="text-muted" href="https://www.dronaaviation.com/forum"  target="_blank">Forum</a></li>
              </ul>
      </Col>
      <Col span={8}>
      <ul className="list-unstyled text-small">

                <li><a className="text-muted" href="https://create.dronaaviation.com/software/drone-programming/cygnus-ide"  target="_blank">CygnusIDE</a></li>
                <li><a className="text-muted" href="https://create.dronaaviation.com/software/drone-programming/plutoblocks"  target="_blank">PlutoBlocks</a></li>
                <li><a className="text-muted" href="https://create.dronaaviation.com/software/drone-programming/api-reference/introduction"  target="_blank">API documentation</a></li>
              </ul>
      </Col>
      <Col span={8}>
      <ul className="list-unstyled text-small">
                <li><a className="text-muted" href="https://www.dronaaviation.com/plutox"  target="_blank">Pluto X</a></li>
                <li><a className="text-muted" href="https://www.dronaaviation.com/pluto-1-2/"  target="_blank">Pluto 1.2</a></li>
                <li><a className="text-muted" href="https://www.dronaaviation.com/store"  target="_blank">Store</a></li>
              </ul>

      </Col>
    </Row>
    <Row gutter={25}>
    <p style={{marginLeft:40}}>
    For more queries contact <br/>
    Email:support@dronaaviation.com<br/>
    Call: +91 7066011808
    </p>
    </Row>
  </div>

        </Footer>

    )

  }else{

 return null;

  }


  }
}

export default DRFooter;
