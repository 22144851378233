import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'


import DRHeader from '../layout/Header'
import PageNotFound from './PageNotFound'

import {NavLink,Link,BrowserRouter, Route,Switch,withRouter} from 'react-router-dom'

import TopMenu from './TopMenu'
import { Layout, Menu, Breadcrumb, Icon,Anchor,BackTop } from 'antd';
import PageContent from'./PageContent'
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;



 


class SoftHome  extends Component {





    render() {

      console.log(this.props.location);
      const paths = this.props.location.pathname.split('/')

      console.log(paths);

      var topMenuKey=""

      if(paths.length===2){
        
        topMenuKey='overview';
 
      }else if(paths.length>3){
   
        topMenuKey=paths[2];

      }


      console.log(this.props.match.params.main_menu);
      console.log(this.props.match.params.sub_menu);
        return (
         


            <Layout>
        

            <Layout style={{ position:"sticky",top:0 , zIndex:1,  background:"#f8f7f7"}}>


    
                <TopMenu type={paths[1].toLowerCase()} selectedKey={topMenuKey.replace(/-/g, ' ').toLowerCase()}/>
  
           
              </Layout>
              <Layout style={{ padding: '', background: '#ddd' }}>



              
                 <Switch>

                 <Route  exact path="/software">
                 <PageContent category='software' subcategory='overview'/>
                 </Route>
                 <Route  exact path="/hardware">
                 <PageContent category='hardware' subcategory='overview'/>
                 </Route>

                 <Route  exact path="/design">
                 <PageContent category='design' subcategory='overview'/>
                 </Route>

                 <Route exact path="/:category/:sub_category" component={PageNotFound} />
                 <Route exact path="/:category/:sub_category/:main_menu/:sub_menu/*" component={PageNotFound} />

                 <Route  path="/:category/:sub_category/:main_menu/:sub_menu"  component={PageContent} />
                 <Route  path="/:category/:sub_category/:main_menu"  component={PageContent} />



                 {/* <Route  path="/Software/:category/:sub_category"  component={SoftIntroduction} /> */}
                 {/* <Route  path="/Software/User"  component={PageContent} /> */}

                  </Switch>
       
           
    

              </Layout>

            <BackTop />
          </Layout>

        )
    
    }

}

export default SoftHome;
