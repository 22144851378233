import { Component } from 'react';

import React from 'react';


import 'antd/dist/antd.css'


import DRHeader from '../layout/Header'
import {NavLink,Link,BrowserRouter, Route,Switch,withRouter} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Icon,Anchor,BackTop } from 'antd';








class SoftTopMenu  extends Component {


    constructor(props) {
      super(props);
      this.state = {
        current: 'overview',
      };
    


    }

    componentDidMount(){

       console.log("Top Menu Mount")
      this.setState({
        current: this.props.selectedKey
      });

    }


    componentDidUpdate(){

       if(this.state.current!==this.props.selectedKey){
        this.setState({
          current: this.props.selectedKey
        });
  

       }
      console.log("Top Menu Update")

    }

    handleClick = e => {
        console.log('click ', e);
        this.setState({
          current: e.key,
        });
     
      window.location.reload();
      };
    
   render() {

          

        return (

                <Menu  style={{background:"#f8f7f7",  marginLeft:130}} onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                <Menu.Item key="overview">

                <Link exact to="/software">Overview </Link>
                </Menu.Item>
                <Menu.Item key="drone programming">

                <Link to="/software/drone-programming/introduction">Drone Programming</Link>
                </Menu.Item>
                <Menu.Item key="remote programming">

                <Link to="/software/remote-programming/introduction">Remote Programming</Link>
                </Menu.Item>
                <Menu.Item key="configuration">

                <Link to="/software/configuration/introduction">Configuration </Link>
                </Menu.Item>

                <Menu.Item key="tutorials">

                <Link to="/software/tutorials/introduction">Tutorials </Link>
                </Menu.Item>

                </Menu>


)}




}




class HardTopMenu  extends Component {


  constructor(props) {
    super(props);
    this.state = {
      current: 'overview',
    };
  


  }

  componentDidMount(){

     console.log("Top Menu Mount")
    this.setState({
      current: this.props.selectedKey
    });

  }


  componentDidUpdate(){

     if(this.state.current!==this.props.selectedKey){
      this.setState({
        current: this.props.selectedKey
      });


     }
    console.log("Top Menu Update")

  }

  handleClick = e => {
      console.log('click ', e);
      this.setState({
        current: e.key,
      });
   
    window.location.reload();
    };
  
 render() {

        

      return (

              <Menu style={{background:"#f8f7f7",  marginLeft:130}} onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
              <Menu.Item key="overview">

              <Link exact to="/hardware">Overview </Link>
              </Menu.Item>
              <Menu.Item key="flight controllers">

              <Link to="/hardware/flight-controllers/introduction">Flight Controllers</Link>
              </Menu.Item>
              <Menu.Item key="accessories">

              <Link to="/hardware/accessories/introduction">Accessories</Link>
              </Menu.Item>
              <Menu.Item key="parts">

              <Link to="/hardware/parts/introduction">Parts </Link>
              </Menu.Item>
              <Menu.Item key="tutorials">

              <Link to="/hardware/tutorials/introduction">Tutorials </Link>
              </Menu.Item>
              
              </Menu>


)}




}



class DesignTopMenu  extends Component {


  constructor(props) {
    super(props);
    this.state = {
      current: 'overview',
    };
  


  }

  componentDidMount(){

     console.log("Top Menu Mount")
    this.setState({
      current: this.props.selectedKey
    });

  }


  componentDidUpdate(){

     if(this.state.current!==this.props.selectedKey){
      this.setState({
        current: this.props.selectedKey
      });


     }
    console.log("Top Menu Update")

  }

  handleClick = e => {
      console.log('click ', e);
      this.setState({
        current: e.key,
      });
   
    window.location.reload();
    };
  
 render() {

        

      return (

              <Menu style={{background:"#f8f7f7", marginLeft:130}} onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
              <Menu.Item key="overview">

              <Link exact to="/design">Overview </Link>
              </Menu.Item>
              <Menu.Item key="forms">

              <Link to="/design/forms/introduction">Forms</Link>
              </Menu.Item>
              <Menu.Item key="accessories">

              <Link to="/design/accessories/introduction">Accessories</Link>
              </Menu.Item>

              <Menu.Item key="tutorials">

              <Link to="/design/tutorials/introduction">Tutorials </Link>
              </Menu.Item>
              </Menu>


)}




}





class TopMenu  extends Component {


     render (){

         if(this.props.type==='software')
              return (<SoftTopMenu selectedKey={this.props.selectedKey}/>)

        if(this.props.type==='hardware')
        return (<HardTopMenu selectedKey={this.props.selectedKey}/>)

        if(this.props.type==='design')
        return (<DesignTopMenu selectedKey={this.props.selectedKey}/>)


     }


}

export default TopMenu;